import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Components
import SectionTitle from "../../components/common/section-title";

//Import Images
import img1 from "../../assets/images/features/ekologiškos pakavimo granulės dūštantiems daiktams.jpg";
import img2 from "../../assets/images/features/ekologiškos pakavimo granulės iš krakmolo.jpg";
import FeatureBox from "../common/feature-box";

class Features extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section" id="features">
          <Container>
            <SectionTitle title1="Produkto" title2="savybės" />
            <Row className="mt-4 pt-4 vertical-content">
              <Col lg="6" className="mt-2">
                <div>
                  <img
                    src={img1}
                    alt="Patikimas ir saugus pakavimo užpildas"
                    title="Patikimas ir saugus pakavimo užpildas"
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col lg="6" className="mt-2 text-center">
                <FeatureBox
                  class=""
                  title="Patikimas ir saugus pakavimo užpildas"
                  desc="Pakavimo granulės yra švelnaus paviršiaus, netrupa, apsaugo dūžtančius daiktus. Išlaiko formą pakuojant
net ir sunkius daiktus. Šios granulės neturi statinio krūvio, todėl yra saugios elektronikai.
Ypač lengvos todėl nepasunkina supakuotų siunčiamų daiktų svorio."
                />
              </Col>
            </Row>
            <Row className="mt-5 vertical-content">
              <Col lg="6" className="mt-2">
                <FeatureBox
                  class="text-center"
                  title="100% suyrantis augalinės kilmės produktas"
                  desc="Biologiškai skaidžios pakavimo granulės yra 100% augalinės kilmės, todėl yra saugios aplinkai bei žmonėms.
Granulės gali būti naudojamos pakartotinai. Pagamintos iš biologiškai suyrančio krakmolo,
todėl yra visiškai tirpios vandenyje, gali būti kompostuojamos žemėje nepalikdamos
toksinių medžiagų ar teršalų dirvožemyje ar vandenyje."
                />
              </Col>
              <Col lg="6" className="mt-2">
                <div>
                  <img
                    src={img2}
                    alt="100% suyrantis augalinės kilmės produktas"
                    title="100% suyrantis augalinės kilmės produktas"
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
