import React, { Component } from 'react';
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Service Box
import ServiceBox from "./service-box";

class Services extends Component {
    state = {
        services : [
            { title : "Digital Design", icon : "mbri-code", description : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor dolore mag na aliqua." },
            { title : "Unlimited Colors", icon : "mbri-features", description : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor dolore mag na aliqua." },
            { title : "Strategy Solutions", icon : "mbri-growing-chart", description : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor dolore mag na aliqua." },
        ],
        services1 : [
            { title : "Awesome Support", icon : "mbri-photos", description : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor dolore mag na aliqua." },
            { title : "Truly Multipurpose", icon : "mbri-responsive", description : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor dolore mag na aliqua." },
            { title : "Easy to customize", icon : "mbri-download", description : "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor dolore mag na aliqua." },
        ],

    }
    render() {
        return (
            <React.Fragment>
        <section id="service" className="section">
            <Container>
            <SectionTitle title1="Apie" title2="mus" />
<p className={'about-us-text'}>
    „Loose Fill“ orientuojasi į saugumą. Saugumą visomis prasmėmis, ne tik siunčiamų prekių tačiau ir saugumą aplinkai bei gamtai. Visuomenės įpročiams kintant ir įprastinį apsipirkinėjimą fizinėse parduotuvėse keičiant apsipirkimu el. erdvėje ieškome būdų kaip išaugusį prekių pristatymą paversti kuo draugiškesniu aplinkai ir gamtai, todėl savo klientams siūlome įprastas plastiko granules keisti į biologiškai skaidžias ir jų pagalba bent maža dalimi prisidėti prie kasdienių atliekų kiekio mažinimo. Mes siekiame, kad biologiškai skaidžios pakavimo granulės taptų ne tik saugiu tačiau ir moderniu prekių pakavimo sprendimu verslui bei namų ūkiams. Pasirūpinkime aplinka kartu, pradėkime jau dabar!
</p>
            </Container>
        </section>
            </React.Fragment>
        );
    }
}

export default Services;
